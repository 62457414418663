<template>
  <b-modal modal-class="audit-trail" dialog-class="audit-trail" v-model="ShowAuditTrail" :no-close-on-backdrop="true"
    size="xl">
    <template v-slot:modal-header="{ close }">
      <h5 class="modal-title">{{ ModalTitle }}</h5>
      <button class="close" @click="Cancel()"></button>
    </template>

    <div>
      <vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="false"
        :paginate-elements-by-height="1800" :filename="PDFFileName" :pdf-quality="2" :manual-pagination="false"
        pdf-format="a4" pdf-orientation="landscape" pdf-content-width="100%" @beforeDownload="beforeDownload($event)"
        ref="html2Pdf">
        <section slot="pdf-content">
          <AuditTrailWithHistory :id="id" :VendorID="VendorID" :FormatTask="FormatTask" :RequestType="'vendormediaamend'" :VendorValidationAttemptCount = "VendorValidationAttemptCounter"></AuditTrailWithHistory>
          <media-amend-overview Columns="4" :ID="id" :Request="Request" :VendorValidationFiles="VendorValidationFiles" class="audit-main"></media-amend-overview>
        </section>
      </vue-html2pdf>
    </div>
    <template v-slot:modal-footer="{ ok, cancel }">
      <div class="text-right footer-buttons">
        <button class="btn custom-button btn-primary" @click="DownloadAsPDF()">
          Download As PDF
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import { AxUpdateTheRequestWithSystemCode } from "../../../utilities/ClientWorkflowStates";
import AuditTrailWithHistory from "@/components/Common/AuditTrailWithHistory";
export default {
  components: {
    VueHtml2pdf, AuditTrailWithHistory
  },
  name: "AuditTrail",
  props: {
    VendorID: {
      required: true,
      type: Number,
    },
    id: {
      required: true,
      type: Number,
    },
    closeAuditModal: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {

      Request: null,
      ShowAuditTrail: true,
      statusList: [],
      ID: null,
      HistoryProcessCount: 0,
      HistoryAuditTrail: [],
      HistoryLoaded: false,
      ShowHistoryStates: false,
      VendorValidationFiles: [],
      VendorValidationAttemptCounter: 0,
    };
  },
  created() {
    this.ShowAuditTrail = true;
    // this.GetWorkflowStates();
    this.$store
      .dispatch("vendormediaamend/GetAuditFormData", parseInt(this.id))
      .then((res) => {
        this.Request = res.data;
        this.$store
          .dispatch("vendorValidation/GetVendorValidationDetails",{
            id : this.id,
            vendorType:"Amend Media",
          })
          .then((res) => {
            if(res) {
              this.VendorID = res.data.ID;
              this.VendorValidationFiles = res.data.Files;
              this.VendorValidationAttemptCounter = res.data.AttemptCounter;
            }
          })
      })
      .catch((err) => {
        alert(err);
      });
  },
  computed: {
    ModalTitle() {
      return this.Request != null
        ? `Audit Trail - ${this.Request.VendorBusinessName}`
        : "Audit Trail";
    },
    PDFFileName() {
      return `${this.ModalTitle}_${moment(new Date()).format(
        "MMDDYYYYHHmmss"
      )}`;
    },
  },
  methods: {
    async GetWorkflowStates() {
      this.$store
        .dispatch("vendormediaamend/GetWorkflowStates", parseInt(this.id))
        .then((res) => {
          this.statusList = res.data.States;
          this.HistoryProcessCount = res.data.HistoryProcessCount;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async GetWorkflowHistoryStates() {
      if (this.HistoryLoaded != true) {
        this.$store
          .dispatch("vendormediaamend/GetWorkflowHistoryStates", parseInt(this.id))
          .then((res) => {
            this.HistoryAuditTrail = res.data;
            this.HistoryLoaded = true;
            this.ShowHistoryStates = true;
          })
          .catch((err) => {
            alert(err);
          });
      }
      if (this.HistoryLoaded == true) {
        this.ShowHistoryStates = true;
      }
    },
    HideHistoryWorkflow() {
      this.ShowHistoryStates = false;
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .set({
          pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        })
        .from(pdfContent)
        .toPdf()
        .save();
    },
    FormatTask(name, state) {
      if (state == "Finished" && name == AxUpdateTheRequestWithSystemCode) {
        return `${name} (${this.Request.CompletedCode})`;
      } else {
        return name;
      }
    },
    FormatDate(date) {
      return date != null && moment(new Date(date)).isValid()
        ? moment(date).format("MM/DD/YYYY")
        : "-";
    },
    Cancel() {
      this.ShowAuditTrail = false;
      this.$emit("closeAudit");
    },
    DownloadAsPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped>
.audit-label {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5161;
  margin-left: 2rem;
  padding-top: 1rem;
}

.panel-list:not(.media-dropdowns) {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.panel-list .panel-body .panel-title {
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  width: 100%;
  margin-left: 0px;
  margin-bottom: 25px !important;
}

.panel-list .panel-body .panel-title label {
  color: #77a2bb;
  margin-bottom: 0px !important;
  padding-top: 13px !important;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.57;
  letter-spacing: normal;
  padding-left: 0px !important;
  min-height: 35px;
}

.panel-list .panel-body .panel-title .btn {
  padding-left: 5px;
  padding-right: 5px;
}

.panel-list .panel-body .panel-title>div {
  padding-right: 0px !important;
  float: right;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #00a1d2;
}

.panel-list .panel-content label {
  text-transform: uppercase;
  color: #2c3865;
  font-size: 12px;
  margin-bottom: 1px !important;
  padding-left: 0px;
}

.panel-list .panel-content p {
  text-transform: uppercase;
  color: #4d5161;
  font-size: 14px;
}

.panel-content.row>div>label:first-child {
  color: #2c3865 !important;
  font-family: "Open Sans" !important;
  font-size: 12px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
}

.panel-content.row>div> :not(:first-child) {
  font-family: "Open Sans" !important;
  font-size: 14px !important;
}

.panel-content.row>div>div {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}

.panel-list>.panel-body {
  margin-top: 1rem !important;
}

.panel-content.row>div {
  margin-bottom: 10px !important;
}

.edit-icon {
  height: 11px;
  width: 11px;
  color: #00a1d2;
}

.request-status {
  background-color: transparent;
  margin-left: 0rem;
  margin-right: 0rem;
  display: flex;
  height: auto;
  border-radius: 5px;
  padding-left: 2rem;
}

.request-status-container {
  background-color: rgba(216, 216, 216, 0.2);
  margin-left: 3rem;
  margin-right: 3rem;

}

.status-bar {
  line-height: 2;
  color: #d5d7e0;
}

.request-status .status {
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}

.request-status .date {
  font-size: 10px;
  font-family: "Open Sans";
  font-weight: regular;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #a2a9ad;
}

.request-status .updatedby {
  font-size: 10px;
  font-family: "Open Sans";
  font-weight: regular;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}

.request-status .comments {
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #00a1d2;
}

.steps.request-status {
  padding-top: 20px;
}

.steps.request-status .step-text>* {
  white-space: normal;
  word-break: break-word;
}

.steps.request-status .step-content {
  min-width: 11rem !important;
  max-width: 11rem !important;
  padding-right: 0px !important;
}
</style>