<template>
<div class="request-status-container">
    <div v-if="IsHistoryAvailable" class="audit-label">Most Recent Audit Trail</div>
    <ul class="steps request-status">
        <li class="step" v-for="(status, index) in States" :key="status.Name" style="display: inline">
            <div class="step-content">
                <span class="step-circle">&nbsp;</span>
                <span class="step-text">
                    <div class="status">
                        {{ FormatTask(status.TaskName, status.TaskState) }}
                    </div>
                    <div class="date">{{ FormatDate(status.UpdatedDate) }}</div>
                    <div class="updatedby">
                        <b-img v-if="status.TaskName == 'Vendor Validation' && VendorID && VendorValidationAttemptCount > 0" @click="ShowAttemptHistory()" :src="historyIcon"/>
                        {{
                        GetStatus(
                          status.TaskState,
                          status.UpdatedBy,
                          index,
                          status.TaskName
                        )
                      }}
                    </div>
                    <a class="comments" v-if="status.Comments" :title="status.Comments" v-b-tooltip.hover>
                        View Comments
                    </a>
                </span>
            </div>
        </li>
    </ul>
    <div v-if="IsHistoryAvailable && ShowHistoryStates" class="audit-label">Previous Audit Trail</div>
    <a class="btn btn-link" v-if="IsHistoryAvailable && !ShowHistoryStates" @click="GetWorkflowHistoryStates">
        <b-img :src="collapseIcon" alt="expand"></b-img> SEE PREVIOUS AUDIT TRAILS
    </a>
    <span v-if="ShowHistoryStates && IsHistoryAvailable">
        <ul v-for="(historyStatusList,parentIndex) in HistoryAuditTrail" :key="historyStatusList.ProcessID" class="steps request-status">
            <li class="step" v-for="(status, index) in historyStatusList.States" :key="status.Name" style="display: inline">
                <div class="step-content">
                    <span class="step-circle">&nbsp;</span>
                    <span class="step-text">
                        <div class="status">
                            {{ FormatTask(status.TaskName, status.TaskState) }}
                        </div>
                        <div class="date">{{ FormatDate(status.UpdatedDate) }}</div>
                        <div class="updatedby">
                            {{
                        GetStatus(
                          status.TaskState,
                          status.UpdatedBy,
                          index,
                          status.TaskName,
                          parentIndex
                        )
                      }}
                        </div>
                        <a class="comments" v-if="status.Comments" :title="status.Comments" v-b-tooltip.hover>
                            View Comments
                        </a>
                    </span>
                </div>
            </li>
        </ul>
    </span>
    <a class="btn btn-link" v-if="IsHistoryAvailable && ShowHistoryStates" @click="HideHistoryWorkflow">
        <b-img :src="expandIcon" alt="expand"></b-img> See less
    </a>
    <b-modal v-if="VendorID" v-model="showModal" class="history-model" title="Attempt History" hide-footer :no-close-on-backdrop="true">
        <AttemptHistory :id="VendorID"></AttemptHistory>
    </b-modal>
</div>
</template>

<script>
import moment from "moment";
import * as workflowStatus from "../../utilities/VendorWorkflowStates";
import AttemptHistory from "./AttemptHistory";
export default {
    components: {
        AttemptHistory: AttemptHistory,
    },
    name: "AuditTrailWithHistory",
    props: {
        FormatTask: Function,
        id: {
            required: true,
            type: Number
        },
        VendorID: {
            required: true,
            type: Number
        },
        RequestType: {
            required: true,
            type: String
        },
        FinalStatus :{
            required: false,
            type: String,
            default: "Update the request with System Code"
        },
        VendorValidationAttemptCount :{
            required: false,
            type: Number,
            default: 0
        },
        DirectorApprover : {
            type: String,
            default:''
        }
    },
    data() {
        return {
            showModal: false,
            collapseIcon: require("@/assets/Icons/arrow-down.svg"),
            expandIcon: require("@/assets/Icons/arrow-up-big.svg"),
            States: [],
            HistoryProcessCount: 0,
            HistoryAuditTrail: [],
            HistoryLoaded: false,
            ShowHistoryStates: false,
            historyIcon: require("@/assets/Icons/History.svg"),
        }
    },
    created() {
        this.GetWorkflowStates();
    },
    methods: {
        ShowAttemptHistory() {
            this.showModal = true;
        },
        async GetWorkflowStates() {
            let storeAction = `${this.RequestType}/GetWorkflowStates`;
            this.$store
                .dispatch(storeAction, parseInt(this.id))
                .then((res) => {
                    this.States = res.data.States;
                    this.HistoryProcessCount = res.data.HistoryProcessCount;
                })
                .catch((err) => {
                    alert(err);
                });
        },
        async GetWorkflowHistoryStates() {
            let storeAction = `${this.RequestType}/GetWorkflowHistoryStates`;
            if (this.HistoryLoaded != true) {
                this.$store
                    .dispatch(storeAction, parseInt(this.id))
                    .then((res) => {
                        this.HistoryAuditTrail = res.data;
                        this.HistoryLoaded = true;
                        this.ShowHistoryStates = true;
                    })
                    .catch((err) => {
                        alert(err);
                    });
            }
            if (this.HistoryLoaded == true) {
                this.ShowHistoryStates = true;
            }
        },
        HideHistoryWorkflow() {
            this.ShowHistoryStates = false;
        },
        FormatDate(date) {
            return date != null && moment(new Date(date)).isValid() ?
                moment(date).format("MM/DD/YYYY") :
                "-";
        },
        GetStatus(taskStatus, updatedBy, index, taskName, parentIndex) {
            let status = "";
            let lastItem = this.States.length - 1 == index;
            if (parentIndex != undefined && parentIndex != null && parseInt(parentIndex) > -1) {
                lastItem = this.HistoryAuditTrail[parentIndex].States.length - 1 == index;
            }
            let firstItem = index == 0;
            switch (taskStatus) {
                case "Finished":
                    status = firstItem ?
                        `Submitted by ${updatedBy}` :
                        lastItem ?
                        `Completed by ${updatedBy}` :
                        taskName.trim() == workflowStatus.VendorValidation ? `Submitted by ${updatedBy}`:
                        `Approved by ${updatedBy}`;
                    break;
                case "Ready":
                    status =
                        taskName == this.FinalStatus ?
                        "Pending for completion" :
                        taskName.trim() == workflowStatus.VendorValidation && this.VendorValidationAttemptCount > 0 ?
                        `Pending Vendor Validation ${this.VendorValidationAttemptCount}`:
                        taskName.trim() == workflowStatus.DirectorApproval && this.DirectorApprover != ''? 
                        `Waiting for ${this.DirectorApprover}'s approval`: `Waiting for approval`;
                    break;
                case "Assigned":
                    status = `Waiting for approval`;
                    break;
                case "Started":
                    status = ``;
                    break;
                case "Canceled":
                    status = lastItem ?
                        `Rejected by ${updatedBy}` :
                        firstItem ?
                        `Submitted by ${updatedBy}` :
                        `Approved by ${updatedBy}`;
                    break;
                default:
                    status = ``;
                    break;
            }
            return status;
        },
    },
    computed: {
        IsHistoryAvailable() {
            return this.HistoryProcessCount > 0;
        },
    }
}
</script>

<style>
.request-status {
    background-color: transparent;
    margin-left: 0rem;
    margin-right: 0rem;
    display: flex;
    height: auto;
    border-radius: 5px;
    padding-left: 2rem;
}

.request-status-container {
    background-color: rgba(216, 216, 216, 0.2);
    margin-left: 3rem;
    margin-right: 3rem;

}

.status-bar {
    line-height: 2;
    color: #d5d7e0;
}

.request-status .status {
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.request-status .date {
    font-size: 10px;
    font-family: "Open Sans";
    font-weight: regular;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #a2a9ad;
}

.request-status .updatedby {
    font-size: 10px;
    font-family: "Open Sans";
    font-weight: regular;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
    padding-right: 5px;
}

.request-status .comments {
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #00a1d2;
}

.steps.request-status {
    padding-top: 20px;
}

.steps.request-status .step-text>* {
    white-space: normal;
    word-break: break-word;
}

.steps.request-status .step-content {
    min-width: 11rem !important;
    max-width: 11rem !important;
    padding-right: 0px !important;
}
.modal.show .modal-dialog {
    min-width: 900px;
}
</style>
